<template>
  <div class="page">
    <!--查询框-->
    <div class="search-box section page-header" style="margin-bottom: 1px;height: auto;">
      <el-form ref="searchForm" :inline="true" :model="query" size="medium">
        <!--        <el-form-item prop="parkingLotId">-->
        <!--          &lt;!&ndash;          <SuggestParking v-model="query.parkingLotId" />&ndash;&gt;-->
        <!--          <el-input-->
        <!--              v-model="query.parkingName"-->
        <!--              placeholder="请输入巡检员姓名/手机号查询"-->
        <!--              prefix-icon="el-icon-search"-->
        <!--              style="width: 254px; margin-right: 10px;margin-left: 38px;"-->
        <!--          />-->
        <!--        </el-form-item>-->
        <el-form-item prop="parkingLotId">
          <SuggestParking
              v-model="query.parkingLotId"
              class="width260"
          />
        </el-form-item>
        <el-form-item prop="orderStatus">
          <el-select
              v-model="query.orderStatus"
              clearable
              placeholder="请选择-订单状态"
              class="width260"
          >
            <el-option label="正常" value="0"></el-option>
            <el-option label="无效" value="2"></el-option>
            <!--            <el-option label="审核" value="2"></el-option>-->
            <!--            <el-option label="复议" value="3"></el-option>-->
          </el-select>
        </el-form-item>
        <el-form-item prop="isArrearage">
          <el-select
              v-model="query.isArrearage"
              clearable
              placeholder="请选择-付款状态"
              class="width260"
          >
            <el-option label="欠费" value="0"></el-option>
            <el-option label="成功" value="1"></el-option>
            <el-option label="交易取消" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="parkingLotId">
          <!--          <SuggestParking v-model="query.parkingLotId" />-->
          <el-input
              v-model="query.parkingOrderIntoId"
              placeholder="请输入订单编号查询"
              prefix-icon="el-icon-search"
              class="width260"
          />
        </el-form-item>
        <el-form-item prop="isPlateNumber">
          <el-select
              v-model="query.isPlateNumber"
              clearable
              placeholder="请选择-有无车牌"
              class="width260"
          >
            <el-option label="有车牌" value="0"></el-option>
            <el-option label="无车牌" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="plateNumber">
          <NumplateSuggest v-model="query.plateNumber"
                           class="width260"/>
        </el-form-item>
        <el-form-item prop="driveType">
          <el-select
              class="width120"
            v-model="query.driveType"
            placeholder="时间范围"
        >
          <el-option label="驶入时间" value="0"></el-option>
          <el-option label="驶出时间" value="1"></el-option>
          <!--              <el-option label="驶入驶出" value="3"></el-option>-->
        </el-select>
          <!--            :default-time="['00:00:00', '23:59:59']"-->
          <el-date-picker
              v-model="query.timeValues"
              :picker-options="pickerOptions"
              align="right"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">查询时间：</div>
          <div class="text" v-if="summaryData.startTime!=null">{{ summaryData.startTime }} ~ {{ summaryData.endTime }}</div>
          <div class="text" v-else>-</div>
        </div>
      </div>
      <div class="content">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ summaryData.total }}</div>
        </div>
      </div>
      <div class="content">
        <div class="content-item">
          <div class="title">应收金额：</div>
          <div class="text">{{ summaryData.receivableAmount }}</div>
        </div>
      </div>
      <div class="content">
        <div class="content-item">
          <div class="title">实收金额：</div>
          <div class="text">{{ summaryData.transactionAmount }}</div>
        </div>
      </div>
      <div class="content">
        <div class="content-item">
          <div class="title">优惠金额：</div>
          <div class="text">{{ summaryData.discountAmount }}</div>
        </div>
      </div>
    </div>

    <div class="content-box section">
      <div>
        <!--        v-loading="isLoading"-->
        <el-table
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column
              align="center"
              fixed="left"
              label="停车场名称"
              prop="parkingLotName"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              fixed="left"
              label="车牌号码"
              prop="plateNumber"
              width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.plateNumber != ''">
                <Numplate :type="scope.row.autoType? scope.row.autoType:'02'">
                  {{ scope.row.plateNumber }}
                </Numplate>
              </span>
            </template>
          </el-table-column>
<!--          <el-table-column-->
<!--              align="center"-->
<!--              label="泊位号"-->
<!--              prop="spaceCode"-->
<!--              width="180"-->
<!--          >-->
<!--            <template slot-scope="scope">-->
<!--              <span v-if="scope.row.parkingSpaceCode.length>4">-->
<!--                {{ scope.row.parkingSpaceCode.substring(scope.row.parkingSpaceCode.length - 4) }}-->
<!--              </span>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column
              align="center"
              label="订单状态"
              prop="status"
              width="180"
          >
            <template slot-scope="scope">
              <span>{{ orderStatus[scope.row.status] }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="驶入时间"
              prop="driveInTime"
              width="180"
          >
            <template slot-scope="scope">
              <span>{{ new Date(scope.row.driveInTime).toLocaleString() }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="停车时长"
              prop="parkingTakeTime"
              width="180"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="驶出时间"
              prop="driveOutTime"
              width="180"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.driveOutTime===''?'':new Date(scope.row.driveOutTime).toLocaleString() }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="订单金额" width="180">
            <template slot-scope="scope">
              <span>{{
                  parseFloat(scope.row.receivableAmount).toFixed(2)
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="已付款" width="180">
            <template slot-scope="scope">
              <span>{{
                  parseFloat(scope.row.transactionAmount).toFixed(2)
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="优惠金额" width="180">
            <template slot-scope="scope">
              <span>{{
                  parseFloat(scope.row.discountAmount).toFixed(2)
                }}</span>
            </template>
          </el-table-column>
          <!--          <el-table-column align="center" label="实收金额" prop="name">-->
          <!--            <el-table-column align="center" label="线上缴费" width="100">-->
          <!--              <template slot-scope="scope">-->
          <!--                <span>{{-->
          <!--                    parseFloat(-->
          <!--                        scope.row.transactionAmount-->
          <!--                    ).toFixed(2)-->
          <!--                  }}</span>-->
          <!--              </template>-->
          <!--            </el-table-column>-->
          <!--            <el-table-column align="center" label="线下缴费" width="100">-->
          <!--              <template slot-scope="scope">-->
          <!--                <span v-if="scope.row.payType === '2'">{{-->
          <!--                  scope.row.transactionAmount-->
          <!--                }}</span>-->
          <!--              </template>-->
          <!--            </el-table-column>-->
          <!--            <el-table-column-->
          <!--                align="center"-->
          <!--                label="优惠金额"-->
          <!--                prop="discountAmount"-->
          <!--                width="100"-->
          <!--            >-->
          <!--              <el-table-column align="center" label="结算" width="100">-->
          <!--              </el-table-column>-->
          <!--              <el-table-column align="center" label="非结算" width="100">-->
          <!--                <template slot-scope="scope">-->
          <!--                  <span>{{-->
          <!--                      parseFloat(-->
          <!--                          scope.row.discountAmount-->
          <!--                      ).toFixed(2)-->
          <!--                    }}</span>-->
          <!--                </template>-->
          <!--              </el-table-column>-->
          <!--            </el-table-column>-->
          <!--          </el-table-column>-->
          <el-table-column
              align="center"
              label="订单编号"
              prop="parkingOrderIntoId"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="缴费状态"
              prop="isArrearage"
              width="240"
          >
            <template slot-scope="scope">
              <span>{{ payStatus[scope.row.isArrearage] }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="驶入口"
              prop="parkingLotIntoGateName"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="驶出口"
              prop="parkingLotOutGateName"
              width="240"
          >
          </el-table-column>
          <!--          <el-table-column-->
          <!--              align="center"-->
          <!--              label="用户手机号"-->
          <!--              prop="mobile"-->
          <!--              width="180"-->
          <!--          >-->
          <!--          </el-table-column>-->
          <el-table-column
              align="center"
              fixed="right"
              label="操作"
              prop="name"
              width="280px"
          >
            <template slot-scope="scope">
              <el-button
                  class="common-text font-size14"
                  size="small"
                  type="text"
                  @click="goDetail(scope.row)"
              >详情
              </el-button
              >
              <el-button
                  class="common-text font-size14"
                  size="small"
                  type="text"
                  @click="complete(scope.row.parkingOrderIntoId)"
              >完结
              </el-button
              >
              <el-button
                  class="common-text font-size14"
                  size="small"
                  type="text"
                  @click="modifyLicensePlate(scope.row.parkingOrderIntoId, scope.row.parkingSpaceId)"
              >修改车牌
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 驶入驶出图片弹窗 -->
      <!-- <el-dialog
        class="common-dialog"
        title="车辆快照"
        :visible.sync="dialogFormVisible"
        :append-to-body="true"
      >
        <div class="photoShow">
          <div class="driveInPhoto">
            <div>
              <el-carousel trigger="click" height="300px" class="photo-width">
                <el-carousel-item>
                  <el-image
                    :src="src"
                    @click="photoDialogFormVisible = true"
                  ></el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div>驶入：</div>
          </div>
          <div class="driveOutPhoto">
            <div>
              <el-carousel trigger="click" height="300px" class="photo-width">
                <el-carousel-item>
                  <el-image
                    :src="src"
                    @click="photoDialogFormVisible = true"
                  ></el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div>驶出：</div>
          </div>
        </div>
      </el-dialog> -->

      <!-- 预览驶入驶出大图 -->
      <!-- <el-dialog
        class="common-dialog"
        title="预览图"
        :visible.sync="photoDialogFormVisible"
        :append-to-body="true"
      >
        <div>
          <el-image :src="src"></el-image>
        </div>
      </el-dialog> -->

      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 完结弹出窗 -->
    <el-dialog
        :append-to-body="true"
        :before-close="completeDialogBeforeClosed"
        :visible.sync="completeDialogFormVisible"
        class="common-dialog"
        title="完结订单"
        width="550px"
    >
      <el-form
          ref="completeForm"
          :model="completeForm"
          :rules="rules"
          label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="驶出时间" required>
              <el-form-item prop="endTime">
                <el-date-picker
                    v-model="completeForm.endTime"
                    placeholder="选择日期时间"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否有效" prop="isCalculFee" required>
              <el-select
                  v-model="completeForm.isCalculFee"
                  placeholder="是否有效"
                  style="width: 100%"
              >
                <el-option label="有效" value="0"></el-option>
                <el-option label="无效" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">完结</el-button>
        <!-- <el-button @click="resetForm('completeForm')">重置</el-button> -->
      </div>
    </el-dialog>

    <!-- 修改车牌弹窗 -->
    <el-dialog
        :append-to-body="true"
        :before-close="modifyDialogBeforeClosed"
        :visible.sync="modifyDialogFormVisible"
        class="common-dialog"
        title="修改车牌"
        width="550px"
    >
      <el-form
          ref="modifyForm"
          :model="modifyForm"
          :rules="modifyRules"
          label-width="100px"
      >
        <el-form-item label="车牌颜色" required>
          <el-form-item prop="color">
            <el-select v-model="modifyForm.color" placeholder="请选择车牌颜色">
              <el-option label="黄牌" value="01"></el-option>
              <el-option label="蓝牌" value="02"></el-option>
              <el-option label="黑牌" value="06"></el-option>
              <el-option label="绿色" value="03"></el-option>
              <el-option label="黄绿" value="04"></el-option>
              <el-option label="白牌" value="23"></el-option>
              <el-option label="其他" value="99"></el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="车牌号码" prop="numPlate">
          <el-input v-model="modifyForm.numPlate"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="reason">
          <el-input
              v-model="modifyForm.reason"
              maxlength="100"
              show-word-limit
              placeholder="请输入备注"
              type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeModify">取消</el-button>
        <el-button type="primary" @click="modifySubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SuggestParking from "@/components/SuggestParking";
// import Settlement from "@/components/Settlement";
import Numplate from "@/components/Numplate";
import NumplateSuggest from "@/components/NumplateSuggest";
import {addParkingOrderExit, getParkingOrderByPage, updateVehicleNumPlate,} from "@/api/parkingOrder";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
// import Passage from "@/components/Passage";
import {dateFormat} from "@/utils/utils";
import {doExport} from "@/api/common";
import {
  checkToken,
} from "@/api/common";
import {MessageBox} from "element-ui";

export default {
  name: "parking_order_inside",
  mixins: [BaseMixin, Permission],
  data() {
    const today = dateFormat("yyyy-mm-dd", new Date());
    const beginDate = today;
    // const endDate = dateFormat("yyyy-mm-dd HH:MM:SS", new Date());
    const endDate = today;
    return {
      dataList: [],
      summaryData:{},
      // isLoading: false,
      completeDialogFormVisible: false, //控制完结弹窗
      modifyDialogFormVisible: false, //修改车牌弹窗
      total: "0",
      discountAmountStats: "0",
      receivableAmountStats: "0",
      transactionAmountStats: "0",
      // 完结表单
      completeForm: {
        parkingOrderIntoId: "",
        endTime: "",
        isCalculFee: '0',
      },
      rules: {
        endTime: [
          {required: true, message: "请选择日期时间", trigger: "change"},
        ],
        isCalculFee: [
          {required: true, message: "请选择是否有效", trigger: "change"},
        ],
      },
      //修改车牌表单
      modifyForm: {
        parkingOrderIntoId: "",
        parkingSpaceId: "",
        color: "",
        numPlate: "",
        reason: "",
      },
      modifyRules: {
        color: [
          {required: true, message: "请选择车牌颜色", trigger: "change"},
        ],
        numPlate: [
          {required: true, message: "请输入车牌号码", trigger: "blur"},
        ],
        reason: [
          {required: true, message: "请输入修改原因", trigger: "blur"},
          {max: 100, message: "长度不能超过100个字符", trigger: "blur"},
        ],
      },
      pickerOptions: {
        disabledDate(time){
          return time.getTime()< new Date().getTime()-24 * 3600 * 1000 *90
              || time.getTime()> new Date().getTime();
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      dialogFormVisible: false, //控制审核弹窗层显示
      photoDialogFormVisible: false,
      orderType: {
        // 0 临停，1白名单，2黑名单，3长租，4预存，5访客，6共享
        0: "临停",
        1: "白名单",
        2: "黑名单",
        3: "长租",
        4: "预存",
        5: "访客",
        6: "共享",
      },
      orderStatus: {
        0: "正常",
        1: "正常",
        2: "无效"
        // 1: "无效",
        // 2: "审核",
        // 3: "复议",
        // "": "正常",
      },
      payStatus: {
        // "": "",
        '0': "欠费",
        '1': "成功",
        '2': "交易取消",
      },
      query: {
        companyName: "", //结算方名称
        parkingLotId: "", //停车场id
        parkingOrderIntoId: "", //订单编号
        parkingOrderType: "", //订单类型
        orderStatus: "", //订单状态
        isArrearage: "", //付款状态
        isPlateNumber: "", //有无车牌
        plateNumber: "", //车牌号id
        parkingLotType: "0",  //1-路内查询 0-路外查询
        phoneNumber: "", //手机号
        parkingSpeaceCode: "", //泊位号
        // driveType: "", //驶入驶出状态  0-驶入  1-驶出
        driveType: "0", //时间范围
        timeValues: [beginDate, endDate], //时间
        startTime: "",
        endTime: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
    };
  },
  components: {
    SuggestParking,
    // Settlement,
    Numplate,
    NumplateSuggest,
    // Passage,
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    //查看停车订单详情
    goDetail(item) {
      sessionStorage.setItem("order_detail", JSON.stringify(item));
      this.$router.push("/road_outside/parking_order/parking_order_detail");
    },
    //重置查询条件
    resetQueryForm() {
      const today = dateFormat("yyyy-mm-dd", new Date());
      const beginDate = today;
      // const endDate = dateFormat("yyyy-mm-dd HH:MM:SS", new Date());
      const endDate = today;
      this.query = {
        companyName: "", //结算方名称
        parkingLotId: "", //停车场id
        parkingOrderIntoId: "", //订单编号
        parkingOrderType: "", //订单类型
        orderStatus: "", //订单状态
        isArrearage: "", //付款状态
        isPlateNumber: "", //有无车牌
        plateNumber: "", //车牌号id
        parkingLotType: "0",  //1-路内查询 0-路外查询
        phoneNumber: "", //手机号
        parkingSpeaceCode: "", //泊位号
        // driveType: "", //驶入驶出状态  0-驶入  1-驶出
        driveType: "1", //时间范围
        timeValues: [beginDate, endDate], //时间
        startTime: "",
        endTime: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      };
      this.query.timeValues = [];
      this.pagination.pageSize = 10;
      this.getNowDate();
      this.doSearch();
    },
    doSearch() {
      this.pagination.pageNo = 1;
      this.search();
    },
    async search() {
      checkToken()
          .then(async res => {
            console.log('checkToken', res)
            if (!res || res.code !== 30) {
              MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
                confirmButtonText: '重新登录',
                callback: () => {
                  window.localStorage.clear()
                  window.$vue.$router.push('/login')
                }
              });
            } else {
              this.isLoading = true;
              const params = this.paramFormat(this.query);
              if (this.query.timeValues != null) {
                if (this.query.driveType == '') {
                  this.$message.warning('请选择时间范围后重试')
                  return false
                }
                params["startTime"] = this.query.timeValues[0];
                params["endTime"] = this.query.timeValues[1];
              }
              const res = await getParkingOrderByPage(params);
              // const res = await getParkingOrderByPage({
              //   pageNo: this.pagination.pageNo,
              //   pageSize: this.pagination.pageSize,
              //   userId: "1",
              // });
              this.isLoading = false;
              if (res && res.code === 30 && res.result) {
                const returnObject = res.returnObject;
                if (returnObject !== "") {
                  this.dataList = returnObject.records;
                  this.summaryData = returnObject
                  this.pagination.totalSize = returnObject.total;
                  this.pagination.totalPages = returnObject.pages;
                  this.total = returnObject.total;
                  this.form.exportSize = returnObject.total;
                  // if (returnObject.stats.discountAmountStats.sum !== undefined) {
                  //   this.discountAmountStats =
                  //       returnObject.stats.discountAmountStats.sum;
                  // }
                  // if (returnObject.stats.receivableAmountStats.sum !== undefined) {
                  //   this.receivableAmountStats =
                  //       returnObject.stats.receivableAmountStats.sum;
                  // }
                  // if (returnObject.stats.transactionAmountStats.sum !== undefined) {
                  //   this.transactionAmountStats =
                  //       returnObject.stats.transactionAmountStats.sum;
                  // }
                } else {
                  this.dataList = [];
                  this.pagination.totalSize = 0;
                  this.pagination.totalPages = 0;
                  this.total = 0;
                  this.discountAmountStats = 0;
                  this.receivableAmountStats = 0;
                  this.transactionAmountStats = 0;
                }
              }
            }
          })
    },
    //完结弹出窗
    complete(item) {
      this.completeDialogFormVisible = true;
      this.completeForm.parkingOrderIntoId = item;
    },
    //添加完结
    submitForm() {
      this.$refs.completeForm.validate((valid) => {
        if (valid) {
          const parkingOrderIntoId = this.completeForm.parkingOrderIntoId;
          const endTime = this.completeForm.endTime;
          const isCalculFee = this.completeForm.isCalculFee;
          console.log(endTime);
          addParkingOrderExit(parkingOrderIntoId, endTime, isCalculFee).then((res) => {
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "完结成功",
                type: "success",
              });
              this.completeDialogFormVisible = false;
              this.doSearch();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭完结弹窗清空表单
    completeDialogBeforeClosed(done) {
      this.$refs.completeForm.resetFields();
      done();
    },

    //修改车牌提交
    modifySubmit() {
      this.$refs.modifyForm.validate((valid) => {
        if (valid) {
          const parkingOrderIntoId = this.modifyForm.parkingOrderIntoId;
          const color = this.modifyForm.color;
          const numPlate = this.modifyForm.numPlate;
          const reason = this.modifyForm.reason;
          const parkingSpaceId = this.modifyForm.parkingSpaceId;

          updateVehicleNumPlate(
              parkingOrderIntoId,
              parkingSpaceId,
              numPlate,
              color,
              reason
          ).then((res) => {
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "修改车牌成功",
                type: "success",
              });
              this.modifyDialogFormVisible = false;
              this.doSearch();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //修改车牌弹窗关闭时清理表单
    modifyDialogBeforeClosed(done) {
      this.$refs.modifyForm.resetFields();
      done();
    },
    //关闭修改车牌
    closeModify() {
      this.modifyDialogFormVisible = false;
      this.$refs.modifyForm.resetFields();
    },
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`);
    // },
    // handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`);
    // },
    //修改车牌弹窗
    modifyLicensePlate(parkingOrderIntoId, parkingSpaceId) {
      this.modifyDialogFormVisible = true;
      this.modifyForm.parkingOrderIntoId = parkingOrderIntoId;
      this.modifyForm.parkingSpaceId = parkingSpaceId;
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.query.timeValues.length > 0) {
        params["startTime"] = this.query.timeValues[0];
        params["endTime"] = this.query.timeValues[1];
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      // const res = searchIncomeFlow(params);
      // var text = window.location.hostname;
      // console.log(text);
      // window.open("http://172.16.69.129:81" + "/ParkingManagermentController/getEsParkingOrderRecord?" + objectToQueryString(params));
      doExport(
          "/ParkingManagermentController/getEsParkingOrderRecord",
          params,
          "路内停车订单.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;

      // if (res && res.code === 30 && res.result) {
      //   loading.close();
      //   this.exportDialogFormVisible = false;
      // }
    },
    //获取当前时间
    getNowDate() {
      const beginToday = dateFormat("yyyy-mm-dd", new Date());
      const beginDate = beginToday;
      const endToday = dateFormat("yyyy-mm-dd", new Date());
      const endDate = endToday;
      this.query.timeValues = [beginDate, endDate];
    },
  },
};
</script>

<style lang="less" scoped>
.photoShow {
  display: flex;
  flex-direction: row;

  .photo-width {
    /deep/ .el-carousel__container {
      width: 430px;
    }
  }

  .driveInPhoto {
    margin: 0 34px;
  }

  .driveOutPhoto {
    margin: 0 26px;
  }
}

/deep/ .el-button--text{
  color: #0768FD !important;
}
</style>
